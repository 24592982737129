@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts.css";
@import "headroom.css";

html,
body {
    font-family: Axiforma, sans-serif;
    scroll-padding-top: 80px;
}

.navigation {
    position: fixed;
    height: 80px;
    width: 100%;
    background-color: white;
    border: none;
    z-index: 10;
    transition: all 0.4s ease-out;
}

.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
}

.loader {
    border-color: transparent;
    border-top-color: #ffffff8f;
    -webkit-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
}

.loader.border-t-black {
    border-top-color: black
}

@-webkit-keyframes spinner {
    0% {
        webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
